import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import "./css/App.css"
import { EffectCoverflow, Navigation } from 'swiper';

import slide_image_1 from '../Assets/SafteyInsImg/image1.jpg';
import slide_image_2 from '../Assets/SafteyInsImg/image2.jpg';
import slide_image_3 from '../Assets/SafteyInsImg/image3.jpg';
import slide_image_4 from '../Assets/SafteyInsImg/image4.jpg';
import slide_image_5 from '../Assets/SafteyInsImg/image5.jpg';
import slide_image_6 from '../Assets/SafteyInsImg/image6.jpg';
import slide_image_7 from '../Assets/SafteyInsImg/image7.jpg';
import slide_image_8 from '../Assets/SafteyInsImg/image8.jpg';
import slide_image_9 from '../Assets/SafteyInsImg/image9.jpg';
import slide_image_10 from '../Assets/SafteyInsImg/image10.jpg';
import slide_image_11 from '../Assets/SafteyInsImg/image11.jpg';
import slide_image_12 from '../Assets/SafteyInsImg/image12.jpg';
import slide_image_13 from '../Assets/SafteyInsImg/image13.jpg';
import slide_image_14 from '../Assets/SafteyInsImg/image14.jpg';
import slide_image_15 from '../Assets/SafteyInsImg/image15.jpg';
import slide_image_16 from '../Assets/SafteyInsImg/image16.jpg';
import slide_image_17 from '../Assets/SafteyInsImg/image17.jpg';
import slide_image_18 from '../Assets/SafteyInsImg/image18.jpg';
import slide_image_19 from '../Assets/SafteyInsImg/image19.jpg';
import slide_image_20 from '../Assets/SafteyInsImg/image20.jpg';
import slide_image_21 from '../Assets/SafteyInsImg/image21.jpg';
import slide_image_22 from '../Assets/SafteyInsImg/image22.jpg';
import slide_image_23 from '../Assets/SafteyInsImg/image23.jpg';
import slide_image_24 from '../Assets/SafteyInsImg/image24.jpg';
import slide_image_25 from '../Assets/SafteyInsImg/image25.jpg';
import slide_image_26 from '../Assets/SafteyInsImg/image26.jpg';
import slide_image_27 from '../Assets/SafteyInsImg/image27.jpg';
import slide_image_28 from '../Assets/SafteyInsImg/image28.jpg';
import slide_image_29 from '../Assets/SafteyInsImg/image29.jpg';
import slide_image_30 from '../Assets/SafteyInsImg/image30.jpg';
import slide_image_31 from '../Assets/SafteyInsImg/image31.jpg';
import slide_image_32 from '../Assets/SafteyInsImg/image32.jpg';
import slide_image_33 from '../Assets/SafteyInsImg/image33.jpg';
import slide_image_34 from '../Assets/SafteyInsImg/image34.jpg';
import slide_image_35 from '../Assets/SafteyInsImg/image35.jpg';
import slide_image_36 from '../Assets/SafteyInsImg/image36.jpg';
import slide_image_37 from '../Assets/SafteyInsImg/image37.jpg';
import slide_image_38 from '../Assets/SafteyInsImg/image38.jpg';
import slide_image_39 from '../Assets/SafteyInsImg/image39.jpg';
import slide_image_40 from '../Assets/SafteyInsImg/image40.jpg';
import slide_image_41 from '../Assets/SafteyInsImg/image41.jpg';
import slide_image_42 from '../Assets/SafteyInsImg/image42.jpg';
import slide_image_43 from '../Assets/SafteyInsImg/image43.jpg';
import slide_image_44 from '../Assets/SafteyInsImg/image44.jpg';
import slide_image_45 from '../Assets/SafteyInsImg/image45.jpg';
import slide_image_46 from '../Assets/SafteyInsImg/image46.jpg';

function SafetyandInstructions() {
  const [images] = useState([
    slide_image_1,
    slide_image_2,
    slide_image_3,
    slide_image_4,
    slide_image_5,
    slide_image_6,
    slide_image_7,
    slide_image_8,
    slide_image_9,
    slide_image_10,
    slide_image_11,
    slide_image_12,
    slide_image_13,
    slide_image_14,
    slide_image_15,
    slide_image_16,
    slide_image_17,
    slide_image_18,
    slide_image_19,
    slide_image_20,
    slide_image_21,
    slide_image_22,
    slide_image_23,
    slide_image_24,
    slide_image_25,
    slide_image_26,
    slide_image_27,
    slide_image_28,
    slide_image_29,
    slide_image_30,
    slide_image_31,
    slide_image_32,
    slide_image_33,
    slide_image_34,
    slide_image_35,
    slide_image_36,
    slide_image_37,
    slide_image_38,
    slide_image_39,
    slide_image_40,
    slide_image_41,
    slide_image_42,
    slide_image_43,
    slide_image_44,
    slide_image_45,
    slide_image_46,

  ]);

  return (
    <>
      <div className="container m-0 pb-0" id='SafetyInstructions'>
        <h1 className="heading m-0">Safety & Instruction</h1>
        <Swiper
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          slidesPerView={'auto'}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
          }}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
            clickable: true,
          }}
          modules={[EffectCoverflow, Navigation]}
          className="swiper_container"
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <img src={image} alt={`slide_image_${index}`} />
            </SwiperSlide>
          ))}

          <div className="slider-controler ">
            <div className="swiper-button-prev slider-arrow">
              <ion-icon name="arrow-back-outline"></ion-icon>
            </div>
            <div className="swiper-button-next slider-arrow">
              <ion-icon name="arrow-forward-outline"></ion-icon>
            </div>
          </div>
        </Swiper>
      </div>
    </>
  );
}

export default SafetyandInstructions;




