import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";

const Contactus = () => {
  return (
    <section id="ContactUs" className="p-0 ">

      <Container
        className="bg-light p-0 align-items-center justify-content-center"
        fluid
      >
        <Col className="d-flex justify-content-center justify-content-center align-items-center">
          <Image
            src={require("../Assets/Shri-Balaji-Agencies.png")}
            fluid
            rounded
            alt="Gas"
            className="pb-3"
          />
        </Col>

        {/* display md */}
        <Container className="bg-secondary d-none d-md-block d-lg-none" fluid >
          <Row className="align-items-center ">
            <Col md={12}>
              <Col className="d-flex justify-content-center align-items-center">
                <Image
                  src={require("../Assets/bigred-bharath-gas.png")}
                  fluid
                  rounded
                  alt="Mountains"
                  className="align-items-center p-4 d-block d-lg-none h-25 w-25"
                ></Image>
              </Col>

              <Col md={12}>
                <div className="w-100 text-center">
                  <Image
                    src={require("../Assets/Book-karo.png")}
                    fluid
                    rounded
                    alt="Mountains"
                    className=""
                  ></Image>
                </div>
              </Col>
              <Container
                fluid
                className="d-flex align-items-center justify-content-center"
              >
                <Row className="w-100">
                  <Col md={12}>
                    <div className="w-75 mx-auto text-center">
                      <h2 className="h1 pt-2 fw-bold text-danger">
                        எந்த நேரமும் புக் செய்ய.
                      </h2>
                      <h4 className="pb-3 fs-5 text-justify">
                        தங்களது புதிய சேய்ப்பட்ட தொலைபேசி எண்ணிலிருந்து மட்டும்
                        LPG என்று டைப் செய்து 77180 12345 / 77150 12345 என்று
                        எண்னுக்கு SMS அனுப்பியும் மற்றும் IVRS
                        காலிங்மூலமாகும் புக் செய்யலாம்.
                      </h4>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>

        {/* display lg */}
        <Container className="bg-secondary d-flex d-md-none d-lg-block position-relative" fluid >
          <Row className="align-items-center">
            <Col md={7}>
              <Col className="d-flex justify-content-center align-items-center">
                <Image
                  src={require("../Assets/bigred-bharath-gas.png")}
                  fluid
                  rounded
                  alt="Mountains"
                  className="align-items-center p-4 d-block d-lg-none h-50 w-50"
                ></Image>
              </Col>
              <div className="w-100 text-start">
                <Image
                  src={require("../Assets/Book-karo.png")}
                  fluid
                  rounded
                  alt="Mountains"
                  className="ps-lg-5 ms-lg-5"
                ></Image>
                <div className="ps-md-5 ms-md-5 w-100">
                  <h2 className="h1 pt-2 text-center text-sm-center w-md-100 ps-md-5 fw-bold text-danger">
                    எந்த நேரமும் புக் செய்ய.
                  </h2>
                  <h4 className="ps-md-5 pb-3 text-center text-sm-center">
                    தங்களது பதிவு செய்யப்பட்ட தொலைபேசி எண்ணிலிருந்து மட்டும் LPG
                    என்று டைப் செய்து <br />
                    <span className="d-inline d-sm-none">7718012345 / 7715012345</span>
                    <span className="d-none d-sm-inline">7718012345 / 7715012345</span><br />
                    என்று எண்னுக்கு SMS அனுப்பியும் மற்றும் IVRS காலிங்மூலமாகும் புக் செய்யலாம்.
                  </h4>
                </div>
              </div>
            </Col>
          </Row>
          {/* Cylinder image */}
          <Col className="d-flex justify-content-center align-items-center position-absolute bottom-0 end-0 p-0">
            <Image
              src={require("../Assets/Cylinderflame.png")}
              fluid
              rounded
              alt="Mountains"
              className="d-none d-lg-block p-0 "
            ></Image>
          </Col>
        </Container>
      </Container>
    </section>
  );
};

export default Contactus;